import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Rating from '@mui/material/Rating';
import { useDispatch } from 'react-redux';
import * as Actions from "./store/actions"

export default function MediaCard(p) {
    const dispatch = useDispatch();

    const props = p.props
    const rating = props.calcRating / 10
    function ratingPress(c, d) {
        dispatch(Actions.setReviewDialog({
            open: true,
            props: { ...props, value: d }
        }))
    }
    function handleLinkOpen(c) {
        window.open(c)
    }
    function showDetail() {
        window.open("/item/" + props.id)
    }
    const showGas = props.gas && ["Airport", "FBO"].includes(props.categoryString)
    return (
        <Card sx={{ maxWidth: 320 }}>
            <CardContent>
                <div className='cardCat'>
                    {props.categoryString}
                </div>
                <Typography gutterBottom variant="h6" component="div">
                    {props.name}
                    <hr style={{ margin: "0" }} />
                    <div className='Rating'>
                        <Rating name="size-small" value={rating} precision={0.5} onChange={ratingPress} size="small" />
                        {/* <Typography variant="body2" color="text.secondary" style={{ float: "right" }}>
                            {props.categoryString}
                        </Typography> */}
                    </div>
                    {showGas && (
                        <div className='cardGas'>
                            {props.gas}
                        </div>
                    )}

                </Typography>
                {props.picture && (
                    <CardMedia
                        component="img"
                        height="140"
                        image={props.picture}
                        alt="green iguana"
                    />
                )}
                <Typography variant="body2" color="text.secondary">
                    {props.description}
                </Typography>
                {/* <Typography variant="body2" color="text.secondary">
                    {props.location}
                </Typography> */}

            </CardContent>
            {props.content && (
                <Button size="small" style={{ margin: "0px" }} onClick={() => handleLinkOpen(props.content)}>
                    Web Link
                </Button>
            )}
            <CardActions className='cardActions'>
                {!props.anon && (

                    <Chip
                        avatar={<Avatar alt={props.user.name} src={props.user.picture} />}
                        label={props.user.name}
                        size="small"
                    />
                )}

                <Button size="small" onClick={showDetail}>Details</Button>
            </CardActions>
        </Card>
    );
}