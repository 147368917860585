import React from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from "./store/actions"
import AddItem from './AddItem'
import FilterCat from './FilterCat';
import TextField from '@mui/material/TextField';
import Grid from "@mui/material/Grid";
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';

var debounce
export default function FilterBar(props) {
    const dispatch = useDispatch();
    const open = useSelector((state) => state.addItemDialog.open)
    // const search = useSelector((state) => state.searchString)
    const [search, setSearch] = React.useState("");
    const [view, setView] = React.useState('module');

    const handleChange = (event, nextView) => {
        setView(nextView);
        let tableView = false
        if (nextView === "list") {
            tableView = true
        }
        dispatch(Actions.setTableView(tableView));
    };

    function handleSeach(e) {
        let val = e.target.value
        setSearch(val)
        if (debounce) clearTimeout(debounce);
        debounce = setTimeout(done, 300);
        function done() {
            dispatch(Actions.setSearchString(val));
        }
    }

    function addClick(p) {
        if (open) {
            dispatch(Actions.setAddItem(null));
        } else {
            dispatch(Actions.setAddItem({ open: true, props: null }));

        }
    }

    function ToggleGroup() {
        return (
            <ToggleButtonGroup
                value={view}
                exclusive
                size="small"
                onChange={handleChange}
            >

                <ToggleButton value="module" aria-label="module">
                    <ViewModuleIcon />
                </ToggleButton>
                <ToggleButton value="list" aria-label="list">
                    <ViewListIcon />
                </ToggleButton>
            </ToggleButtonGroup>
        )
    }

    return (
        <div className='filterBar'>
            <div className='filterOpts'>

                <Grid container alignItems="center" direction={"row"} spacing={2}>
                    <Grid item>
                        <ToggleGroup />
                    </Grid>
                    <Grid item>
                        <FilterCat />
                    </Grid>
                    <Grid item>
                        <TextField
                            name="search"
                            label="Search..."
                            variant="filled"
                            value={search}
                            onChange={handleSeach}
                        />
                    </Grid>
                </Grid>
            </div>
            {open && (
                <AddItem />
            )}
            <div>
                <Button className='addButton' variant="contained" onClick={addClick} startIcon={<AddCircleIcon />}>
                    Add New
                </Button>
            </div>

        </div>
    )
}