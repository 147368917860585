import * as React from 'react';
import { useParams } from "react-router-dom";
import * as ib from './ibdata';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from "./store/actions"
import Chip from '@mui/material/Chip';
import ImageList from "@mui/material/ImageList";
import ReviewCard from "./ReviewCard";
import Button from '@mui/material/Button';
import AddItem from './AddItem'
import AddReview from './AddReview';

export default function ItemDetail(pp) {
    let { itemid } = useParams()
    let [props, setItem] = React.useState(null)
    const open = useSelector((state) => state.addItemDialog.open)
    const openR = useSelector((state) => state.reviewDialog.open)

    const dispatch = useDispatch();
    function editClick(p) {
        if (open) {
            dispatch(Actions.setAddItem(null));
        } else {
            dispatch(Actions.setAddItem({ open: true, props: props }));

        }
    }
    function ratingPress(c, d) {
        dispatch(Actions.setReviewDialog({
            open: true,
            props: { ...props, value: d }
        }))
    }

    React.useEffect(() => {
        async function getObj() {
            let data = await ib.getItem(itemid);
            if (data && data.data.getItem) {
                let dt = data.data.getItem
                if (dt.aiInfo) {
                    let st = dt.aiInfo
                    // st = st.replace(/\n/g, "<p>")
                    dt.aiInfo = st
                }
                setItem(dt)
            }
        }
        if (itemid)
            getObj()
    }, [itemid])

    let rating = props ? props.calcRating / 10 : 0
    function handleLinkOpen(c) {
        window.open(c)
    }
    if (!props) return (
        <>
        </>
    )

    return (
        <div className='singleItem'>
            <Typography gutterBottom variant="h2" component="div" >
                <div className='singleHeader'>

                    {props.name}

                    <Button size="small" className='singleAvatar' onClick={editClick}>
                        Edit
                    </Button>
                    {!props.anon && (

                        <Chip
                            className='singleAvatar'
                            avatar={<Avatar alt={props.user.name} src={props.user.picture} />}
                            label={props.user.name}
                            size="small"
                        />
                    )}

                </div>

                <hr style={{ margin: "0" }} />
                <div className='Rating'>
                    <Rating name="size-small" value={rating} precision={0.5} onChange={ratingPress} />
                    <Typography variant="body1" color="text.secondary" style={{ float: "right" }}>
                        {props.categoryString}
                    </Typography>
                </div>
            </Typography>
            {props.picture && (
                <img src={props.picture} height="256" alt="props" />
            )}
            <Typography variant="body1" color="text.secondary">
                {props.description}
            </Typography>
            <Typography variant="body1" color="text.secondary">
                {props.location}
            </Typography>
            {props.content && (
                <Button onClick={() => handleLinkOpen(props.content)}>
                    {props.content}
                </Button>
            )}
            <hr style={{ margin: "0" }} />
            {props.aiInfo && (
                <>
                <b>AI REVIEW</b>
                    <div className='aiInfo'>
                        {props.aiInfo}

                    </div>
                    <hr style={{ margin: "0" }} />
                </>
            )}

            <div className='cardImageList'>

                <ImageList cellHeight={"auto"} className="mainCard" gap={8} cols={4}  >
                    {props.reviews && props.reviews.items.map(i => (
                        <ReviewCard key={i.id} id={i.id} item={i} style={{ margin: "0.3em" }} />
                    ))}
                </ImageList>
            </div>
            {open && (
                <AddItem />
            )}
            {openR && (
                <AddReview />
            )}
        </div>
    )
}