export const SET_USER = "[MAIN] __SET_USER__ "
export const ADD_ITEM_DIALOG = "[MAIN] __ADD_ITEM_DIALOG__"
export const SET_ITEMS = "[MAIN] SET_ITEMS"
export const REVIEW_DIALOG = "[MAIN] REVIEW_DIALOG"
export const FILTER_CAT = "[MAIN] FILTER_CAT"
export const FILTER_LOC = "[MAIN] FILTER_LOC"
export const SET_MAP_COORDS = "[MAIN] SET_MAP_COORDS"
export const SET_SEARCH_STRING = "[MAIN] SET_SEARCH_STRING"
export const SET_TABLE_VIEW = "[MAIN] SET_TABLE_VIEW"

export function setTableView(data) {
    return {
        type: SET_TABLE_VIEW,
        data
    }
}

export function setSearchString(data) {
    return {
        type: SET_SEARCH_STRING,
        data
    }
}

export function setMapCoords(data) {
    return {
        type: SET_MAP_COORDS,
        data
    }
}

export function setUser(data) {
    return {
        type: SET_USER,
        data
    }
}

export function setFilterCat(data) {
    return {
        type: FILTER_CAT,
        data
    }
}

export function setFilterLoc(data) {
    return {
        type: FILTER_LOC,
        data
    }
}

export function setAddItem(data) {
    return {
        type: ADD_ITEM_DIALOG,
        data
    }
}


export function setReviewDialog(data) {
    return {
        type: REVIEW_DIALOG,
        data
    }
}

export function setItems(data) {
    return {
        type: SET_ITEMS,
        data
    }
}