/* eslint-disable react-hooks/exhaustive-deps  */

import React from 'react'
import { useDispatch } from 'react-redux'
import NavBar from './NavBar'
import { useEffect } from 'react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import awsConfig from './aws-exports';
import MainList from './MainList';
// import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import * as ib from "./ibdata"
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ItemDetail from './ItemDetail';
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = awsConfig.oauth.redirectSignIn.split(',');

const [
  localRedirectSignOut,
  productionRedirectSignOut,
] = awsConfig.oauth.redirectSignOut.split(',');

if (awsConfig.oauth) {
  awsConfig.oauth.redirectSignIn = window.location.origin + "/";
  awsConfig.oauth.redirectSignOut = window.location.origin + "/";
}
const userSave = localStorage.getItem("user")
if (!userSave) {
  awsConfig.aws_appsync_authenticationType = "AWS_IAM"
}

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  }
}

Amplify.configure(updatedAwsConfig);

function App(props) {
  const dispatch = useDispatch();
  function foundUser(user) {
    if (!user) {
      return
    }
    // let idToken = user.getIdToken();
    // console.dir(idToken);
    if (window.location.href.includes("/?code")) {
      return
    }
    if (user && !userSave) {
      localStorage.setItem("user", JSON.stringify(user))
    }
    if (user && !userSave) {
      ib.reload(1000);
    }
    ib.findCreateUserProfile(user, dispatch)
  }

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then(userData => foundUser(userData));
          break;
        case 'signOut':
          foundUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
        default:
          break
      }
    });

    getUser();
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => foundUser(userData))
      .catch(() => console.log('Not signed in'));
  }
  function MainApp() {
    return (
      <>
        <div>
          <NavBar />
        </div>
        <div>
          <MainList />
        </div>
      </>
    )
  }
  return (
    <Router>
      <Routes>
        <Route path="/item/:itemid" element={<ItemDetail {...props} />} />
        <Route path="/" element={<MainApp />} />
      </Routes>
    </Router>
  )
}

export default App
