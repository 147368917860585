/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCategory = /* GraphQL */ `
  subscription OnCreateCategory($filter: ModelSubscriptionCategoryFilterInput) {
    onCreateCategory(filter: $filter) {
      id
      name
      content
      createdAt
      updatedAt
      items {
        items {
          id
          name
          categoryString
          description
          content
          calcRating
          location
          latLong
          airportCode
          picture
          createdAt
          updatedAt
          lat
          lng
          gas
          anon
          aiInfo
          categoryItemsId
          userProfileItemsId
        }
        nextToken
      }
    }
  }
`;
export const onUpdateCategory = /* GraphQL */ `
  subscription OnUpdateCategory($filter: ModelSubscriptionCategoryFilterInput) {
    onUpdateCategory(filter: $filter) {
      id
      name
      content
      createdAt
      updatedAt
      items {
        items {
          id
          name
          categoryString
          description
          content
          calcRating
          location
          latLong
          airportCode
          picture
          createdAt
          updatedAt
          lat
          lng
          gas
          anon
          aiInfo
          categoryItemsId
          userProfileItemsId
        }
        nextToken
      }
    }
  }
`;
export const onDeleteCategory = /* GraphQL */ `
  subscription OnDeleteCategory($filter: ModelSubscriptionCategoryFilterInput) {
    onDeleteCategory(filter: $filter) {
      id
      name
      content
      createdAt
      updatedAt
      items {
        items {
          id
          name
          categoryString
          description
          content
          calcRating
          location
          latLong
          airportCode
          picture
          createdAt
          updatedAt
          lat
          lng
          gas
          anon
          aiInfo
          categoryItemsId
          userProfileItemsId
        }
        nextToken
      }
    }
  }
`;
export const onCreateItem = /* GraphQL */ `
  subscription OnCreateItem($filter: ModelSubscriptionItemFilterInput) {
    onCreateItem(filter: $filter) {
      id
      name
      categoryString
      category {
        id
        name
        content
        createdAt
        updatedAt
        items {
          nextToken
        }
      }
      description
      content
      calcRating
      location
      latLong
      airportCode
      picture
      createdAt
      updatedAt
      reviews {
        items {
          id
          rating
          createdAt
          updatedAt
          content
          vote
          anon
          itemReviewsId
          userProfileReviewsId
        }
        nextToken
      }
      user {
        id
        ranking
        name
        picture
        createdAt
        updatedAt
        content
        reviews {
          nextToken
        }
        items {
          nextToken
        }
      }
      lat
      lng
      gas
      anon
      aiInfo
      categoryItemsId
      userProfileItemsId
    }
  }
`;
export const onUpdateItem = /* GraphQL */ `
  subscription OnUpdateItem($filter: ModelSubscriptionItemFilterInput) {
    onUpdateItem(filter: $filter) {
      id
      name
      categoryString
      category {
        id
        name
        content
        createdAt
        updatedAt
        items {
          nextToken
        }
      }
      description
      content
      calcRating
      location
      latLong
      airportCode
      picture
      createdAt
      updatedAt
      reviews {
        items {
          id
          rating
          createdAt
          updatedAt
          content
          vote
          anon
          itemReviewsId
          userProfileReviewsId
        }
        nextToken
      }
      user {
        id
        ranking
        name
        picture
        createdAt
        updatedAt
        content
        reviews {
          nextToken
        }
        items {
          nextToken
        }
      }
      lat
      lng
      gas
      anon
      aiInfo
      categoryItemsId
      userProfileItemsId
    }
  }
`;
export const onDeleteItem = /* GraphQL */ `
  subscription OnDeleteItem($filter: ModelSubscriptionItemFilterInput) {
    onDeleteItem(filter: $filter) {
      id
      name
      categoryString
      category {
        id
        name
        content
        createdAt
        updatedAt
        items {
          nextToken
        }
      }
      description
      content
      calcRating
      location
      latLong
      airportCode
      picture
      createdAt
      updatedAt
      reviews {
        items {
          id
          rating
          createdAt
          updatedAt
          content
          vote
          anon
          itemReviewsId
          userProfileReviewsId
        }
        nextToken
      }
      user {
        id
        ranking
        name
        picture
        createdAt
        updatedAt
        content
        reviews {
          nextToken
        }
        items {
          nextToken
        }
      }
      lat
      lng
      gas
      anon
      aiInfo
      categoryItemsId
      userProfileItemsId
    }
  }
`;
export const onCreateReview = /* GraphQL */ `
  subscription OnCreateReview($filter: ModelSubscriptionReviewFilterInput) {
    onCreateReview(filter: $filter) {
      id
      item {
        id
        name
        categoryString
        category {
          id
          name
          content
          createdAt
          updatedAt
        }
        description
        content
        calcRating
        location
        latLong
        airportCode
        picture
        createdAt
        updatedAt
        reviews {
          nextToken
        }
        user {
          id
          ranking
          name
          picture
          createdAt
          updatedAt
          content
        }
        lat
        lng
        gas
        anon
        aiInfo
        categoryItemsId
        userProfileItemsId
      }
      rating
      createdAt
      updatedAt
      content
      user {
        id
        ranking
        name
        picture
        createdAt
        updatedAt
        content
        reviews {
          nextToken
        }
        items {
          nextToken
        }
      }
      vote
      anon
      itemReviewsId
      userProfileReviewsId
    }
  }
`;
export const onUpdateReview = /* GraphQL */ `
  subscription OnUpdateReview($filter: ModelSubscriptionReviewFilterInput) {
    onUpdateReview(filter: $filter) {
      id
      item {
        id
        name
        categoryString
        category {
          id
          name
          content
          createdAt
          updatedAt
        }
        description
        content
        calcRating
        location
        latLong
        airportCode
        picture
        createdAt
        updatedAt
        reviews {
          nextToken
        }
        user {
          id
          ranking
          name
          picture
          createdAt
          updatedAt
          content
        }
        lat
        lng
        gas
        anon
        aiInfo
        categoryItemsId
        userProfileItemsId
      }
      rating
      createdAt
      updatedAt
      content
      user {
        id
        ranking
        name
        picture
        createdAt
        updatedAt
        content
        reviews {
          nextToken
        }
        items {
          nextToken
        }
      }
      vote
      anon
      itemReviewsId
      userProfileReviewsId
    }
  }
`;
export const onDeleteReview = /* GraphQL */ `
  subscription OnDeleteReview($filter: ModelSubscriptionReviewFilterInput) {
    onDeleteReview(filter: $filter) {
      id
      item {
        id
        name
        categoryString
        category {
          id
          name
          content
          createdAt
          updatedAt
        }
        description
        content
        calcRating
        location
        latLong
        airportCode
        picture
        createdAt
        updatedAt
        reviews {
          nextToken
        }
        user {
          id
          ranking
          name
          picture
          createdAt
          updatedAt
          content
        }
        lat
        lng
        gas
        anon
        aiInfo
        categoryItemsId
        userProfileItemsId
      }
      rating
      createdAt
      updatedAt
      content
      user {
        id
        ranking
        name
        picture
        createdAt
        updatedAt
        content
        reviews {
          nextToken
        }
        items {
          nextToken
        }
      }
      vote
      anon
      itemReviewsId
      userProfileReviewsId
    }
  }
`;
export const onCreateUserProfile = /* GraphQL */ `
  subscription OnCreateUserProfile(
    $filter: ModelSubscriptionUserProfileFilterInput
  ) {
    onCreateUserProfile(filter: $filter) {
      id
      ranking
      name
      picture
      createdAt
      updatedAt
      content
      reviews {
        items {
          id
          rating
          createdAt
          updatedAt
          content
          vote
          anon
          itemReviewsId
          userProfileReviewsId
        }
        nextToken
      }
      items {
        items {
          id
          name
          categoryString
          description
          content
          calcRating
          location
          latLong
          airportCode
          picture
          createdAt
          updatedAt
          lat
          lng
          gas
          anon
          aiInfo
          categoryItemsId
          userProfileItemsId
        }
        nextToken
      }
    }
  }
`;
export const onUpdateUserProfile = /* GraphQL */ `
  subscription OnUpdateUserProfile(
    $filter: ModelSubscriptionUserProfileFilterInput
  ) {
    onUpdateUserProfile(filter: $filter) {
      id
      ranking
      name
      picture
      createdAt
      updatedAt
      content
      reviews {
        items {
          id
          rating
          createdAt
          updatedAt
          content
          vote
          anon
          itemReviewsId
          userProfileReviewsId
        }
        nextToken
      }
      items {
        items {
          id
          name
          categoryString
          description
          content
          calcRating
          location
          latLong
          airportCode
          picture
          createdAt
          updatedAt
          lat
          lng
          gas
          anon
          aiInfo
          categoryItemsId
          userProfileItemsId
        }
        nextToken
      }
    }
  }
`;
export const onDeleteUserProfile = /* GraphQL */ `
  subscription OnDeleteUserProfile(
    $filter: ModelSubscriptionUserProfileFilterInput
  ) {
    onDeleteUserProfile(filter: $filter) {
      id
      ranking
      name
      picture
      createdAt
      updatedAt
      content
      reviews {
        items {
          id
          rating
          createdAt
          updatedAt
          content
          vote
          anon
          itemReviewsId
          userProfileReviewsId
        }
        nextToken
      }
      items {
        items {
          id
          name
          categoryString
          description
          content
          calcRating
          location
          latLong
          airportCode
          picture
          createdAt
          updatedAt
          lat
          lng
          gas
          anon
          aiInfo
          categoryItemsId
          userProfileItemsId
        }
        nextToken
      }
    }
  }
`;
