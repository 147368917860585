/* eslint-disable react-hooks/exhaustive-deps  */

import React from 'react'
import FilterBar from './FilterBar';
import * as ib from "./ibdata"
import MediaCard from './MediaCard';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from "./store/actions"
import ImageList from "@mui/material/ImageList";
import Map from './Map.js';
import DataTable from './DataTable';
import AddReview from './AddReview';

var allList = [];
export default function MainList() {
    // eslint-disable-next-line
    const dispatch = useDispatch();
    const filteredItems = useSelector((state) => state.filteredItems)
    const tableView = useSelector((state) => state.tableView)
    const open = useSelector((state) => state.reviewDialog.open)

    React.useEffect(() => {
        var s2 = null
        var s3 = null
        function gotCat(cl) {
            if (cl) {
                allList = [...allList, ...cl]
            } else {
                // ib.doneCats(allList)
            }
        }

        function gotItem(cl) {
            if (!cl) return
            let ss = {}
            cl.forEach(c => {
                if (!ss[c.categoryString]) {
                    ss[c.categoryString] = {}
                }
                ss[c.categoryString][c.id] = c
            })
            dispatch(Actions.setItems(ss))
        }
        s2 = ib.subscribeCategory(gotCat)
        s3 = ib.subscribeItem(gotItem)

        return () => {
            if (s2) s2.unsubscribe()
            if (s3) s3.unsubscribe()
        }
    }, [])
    const numberOfCols = window.innerWidth > 1000 ? 3 : 2
    const mobile = ib.isMobile()
    return (
        <div className={mobile ? "mainDivMob": "mainDiv"}>
            <div className={mobile ? "cardImageListMob" : 'cardImageList'}>
                <FilterBar />
                <div className='cardImage'>
                    {tableView ? (
                        <>
                            <DataTable items={filteredItems} />
                        </>
                    ) : (
                        <ImageList cellHeight={"auto"} className="mainCard" gap={8} cols={numberOfCols}  >
                            {Object.keys(filteredItems).map(p => (
                                Object.keys(filteredItems[p]).map(r => (
                                    <MediaCard key={r} props={filteredItems[p][r]} style={{ margin: "0.3em" }} />
                                ))
                            ))}
                        </ImageList>
                    )}

                </div>

            </div>
            <div className='mapArea'>
                <Map />
            </div>
            {open && (
                <AddReview />
            )}
        </div >
    )
}