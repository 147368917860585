import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import HelpIcon from '@mui/icons-material/Help';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import { useSelector } from 'react-redux'
import { Auth } from 'aws-amplify';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import * as ib from './ibdata';

export default function MenuAppBar() {
  const user = useSelector((state) => state.userProfile)

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    // setShowAuth(true);
    setAnchorEl(null);
  };
  function signOut() {
    localStorage.removeItem("user");
    Auth.signOut()
    ib.reload(300);
  }
  function helpClic() {
    window.open("https://puberoy.medium.com/how-to-use-pirep-zone-445f3ca30935")
  }
  function reloadClick() {
    setTimeout(function () {
      window.location.reload(1);
    }, 500);
  }
  const picture = user && user.picture ? user.picture : user ? user.name : null
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Pilot reports and reviews
        </Typography>
        <Button style={{ color: "orange" }} onClick={reloadClick}>
          Reload
        </Button>
        <Button style={{ color: "white" }} onClick={helpClic} startIcon={<HelpIcon />}>
          Help
        </Button>
        {user && (
          <div>

            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              {picture ? (
                <Avatar alt="User Avatar" src={picture}>
                  {user.name[0]}
                </Avatar>
              ) : (
                <AccountCircle />
              )}
              <span className='goldContent'>
                {user.ranking}
                <AirplaneTicketIcon />
              </span>

            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={signOut}>Sign Out</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
