/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      content
      createdAt
      updatedAt
      items {
        items {
          id
          name
          categoryString
          description
          content
          calcRating
          location
          latLong
          airportCode
          picture
          createdAt
          updatedAt
          lat
          lng
          gas
          anon
          aiInfo
          categoryItemsId
          userProfileItemsId
        }
        nextToken
      }
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      content
      createdAt
      updatedAt
      items {
        items {
          id
          name
          categoryString
          description
          content
          calcRating
          location
          latLong
          airportCode
          picture
          createdAt
          updatedAt
          lat
          lng
          gas
          anon
          aiInfo
          categoryItemsId
          userProfileItemsId
        }
        nextToken
      }
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      content
      createdAt
      updatedAt
      items {
        items {
          id
          name
          categoryString
          description
          content
          calcRating
          location
          latLong
          airportCode
          picture
          createdAt
          updatedAt
          lat
          lng
          gas
          anon
          aiInfo
          categoryItemsId
          userProfileItemsId
        }
        nextToken
      }
    }
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem(
    $input: CreateItemInput!
    $condition: ModelItemConditionInput
  ) {
    createItem(input: $input, condition: $condition) {
      id
      name
      categoryString
      category {
        id
        name
        content
        createdAt
        updatedAt
        items {
          nextToken
        }
      }
      description
      content
      calcRating
      location
      latLong
      airportCode
      picture
      createdAt
      updatedAt
      reviews {
        items {
          id
          rating
          createdAt
          updatedAt
          content
          vote
          anon
          itemReviewsId
          userProfileReviewsId
        }
        nextToken
      }
      user {
        id
        ranking
        name
        picture
        createdAt
        updatedAt
        content
        reviews {
          nextToken
        }
        items {
          nextToken
        }
      }
      lat
      lng
      gas
      anon
      aiInfo
      categoryItemsId
      userProfileItemsId
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
      name
      categoryString
      category {
        id
        name
        content
        createdAt
        updatedAt
        items {
          nextToken
        }
      }
      description
      content
      calcRating
      location
      latLong
      airportCode
      picture
      createdAt
      updatedAt
      reviews {
        items {
          id
          rating
          createdAt
          updatedAt
          content
          vote
          anon
          itemReviewsId
          userProfileReviewsId
        }
        nextToken
      }
      user {
        id
        ranking
        name
        picture
        createdAt
        updatedAt
        content
        reviews {
          nextToken
        }
        items {
          nextToken
        }
      }
      lat
      lng
      gas
      anon
      aiInfo
      categoryItemsId
      userProfileItemsId
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem(
    $input: DeleteItemInput!
    $condition: ModelItemConditionInput
  ) {
    deleteItem(input: $input, condition: $condition) {
      id
      name
      categoryString
      category {
        id
        name
        content
        createdAt
        updatedAt
        items {
          nextToken
        }
      }
      description
      content
      calcRating
      location
      latLong
      airportCode
      picture
      createdAt
      updatedAt
      reviews {
        items {
          id
          rating
          createdAt
          updatedAt
          content
          vote
          anon
          itemReviewsId
          userProfileReviewsId
        }
        nextToken
      }
      user {
        id
        ranking
        name
        picture
        createdAt
        updatedAt
        content
        reviews {
          nextToken
        }
        items {
          nextToken
        }
      }
      lat
      lng
      gas
      anon
      aiInfo
      categoryItemsId
      userProfileItemsId
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      id
      item {
        id
        name
        categoryString
        category {
          id
          name
          content
          createdAt
          updatedAt
        }
        description
        content
        calcRating
        location
        latLong
        airportCode
        picture
        createdAt
        updatedAt
        reviews {
          nextToken
        }
        user {
          id
          ranking
          name
          picture
          createdAt
          updatedAt
          content
        }
        lat
        lng
        gas
        anon
        aiInfo
        categoryItemsId
        userProfileItemsId
      }
      rating
      createdAt
      updatedAt
      content
      user {
        id
        ranking
        name
        picture
        createdAt
        updatedAt
        content
        reviews {
          nextToken
        }
        items {
          nextToken
        }
      }
      vote
      anon
      itemReviewsId
      userProfileReviewsId
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      id
      item {
        id
        name
        categoryString
        category {
          id
          name
          content
          createdAt
          updatedAt
        }
        description
        content
        calcRating
        location
        latLong
        airportCode
        picture
        createdAt
        updatedAt
        reviews {
          nextToken
        }
        user {
          id
          ranking
          name
          picture
          createdAt
          updatedAt
          content
        }
        lat
        lng
        gas
        anon
        aiInfo
        categoryItemsId
        userProfileItemsId
      }
      rating
      createdAt
      updatedAt
      content
      user {
        id
        ranking
        name
        picture
        createdAt
        updatedAt
        content
        reviews {
          nextToken
        }
        items {
          nextToken
        }
      }
      vote
      anon
      itemReviewsId
      userProfileReviewsId
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      id
      item {
        id
        name
        categoryString
        category {
          id
          name
          content
          createdAt
          updatedAt
        }
        description
        content
        calcRating
        location
        latLong
        airportCode
        picture
        createdAt
        updatedAt
        reviews {
          nextToken
        }
        user {
          id
          ranking
          name
          picture
          createdAt
          updatedAt
          content
        }
        lat
        lng
        gas
        anon
        aiInfo
        categoryItemsId
        userProfileItemsId
      }
      rating
      createdAt
      updatedAt
      content
      user {
        id
        ranking
        name
        picture
        createdAt
        updatedAt
        content
        reviews {
          nextToken
        }
        items {
          nextToken
        }
      }
      vote
      anon
      itemReviewsId
      userProfileReviewsId
    }
  }
`;
export const createUserProfile = /* GraphQL */ `
  mutation CreateUserProfile(
    $input: CreateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    createUserProfile(input: $input, condition: $condition) {
      id
      ranking
      name
      picture
      createdAt
      updatedAt
      content
      reviews {
        items {
          id
          rating
          createdAt
          updatedAt
          content
          vote
          anon
          itemReviewsId
          userProfileReviewsId
        }
        nextToken
      }
      items {
        items {
          id
          name
          categoryString
          description
          content
          calcRating
          location
          latLong
          airportCode
          picture
          createdAt
          updatedAt
          lat
          lng
          gas
          anon
          aiInfo
          categoryItemsId
          userProfileItemsId
        }
        nextToken
      }
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile(
    $input: UpdateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    updateUserProfile(input: $input, condition: $condition) {
      id
      ranking
      name
      picture
      createdAt
      updatedAt
      content
      reviews {
        items {
          id
          rating
          createdAt
          updatedAt
          content
          vote
          anon
          itemReviewsId
          userProfileReviewsId
        }
        nextToken
      }
      items {
        items {
          id
          name
          categoryString
          description
          content
          calcRating
          location
          latLong
          airportCode
          picture
          createdAt
          updatedAt
          lat
          lng
          gas
          anon
          aiInfo
          categoryItemsId
          userProfileItemsId
        }
        nextToken
      }
    }
  }
`;
export const deleteUserProfile = /* GraphQL */ `
  mutation DeleteUserProfile(
    $input: DeleteUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    deleteUserProfile(input: $input, condition: $condition) {
      id
      ranking
      name
      picture
      createdAt
      updatedAt
      content
      reviews {
        items {
          id
          rating
          createdAt
          updatedAt
          content
          vote
          anon
          itemReviewsId
          userProfileReviewsId
        }
        nextToken
      }
      items {
        items {
          id
          name
          categoryString
          description
          content
          calcRating
          location
          latLong
          airportCode
          picture
          createdAt
          updatedAt
          lat
          lng
          gas
          anon
          aiInfo
          categoryItemsId
          userProfileItemsId
        }
        nextToken
      }
    }
  }
`;
