import * as autoQuery from './graphql/queries'
import * as autoMutation from './graphql/mutations'
import * as autoSub from './graphql/subscriptions'
import * as Actions from "./store/actions"
import { v4 as uuid } from "uuid";
import { Storage } from 'aws-amplify'

import { API, graphqlOperation } from 'aws-amplify';
import config from './aws-exports'

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config

export const CATEGORIES = [
    "Airport", "FBO", "Restaurant", "Gear", "Flight School", "DPE", "Sight Seeing", "CFI", "Online Content"
]
export function listCategorys(cb, nextToken) {
    const cmd = { limit: 100, nextToken: nextToken }
    API.graphql(graphqlOperation(autoQuery.listCategories, cmd)).then(res => {
        const dat = res.data.listCategories
        if (dat) cb(dat.items)
        if (dat && dat.nextToken) {
            listCategorys(cb, dat.nextToken)
        }
    })
}

export function subscribeCategory(cb) {
    listCategorys(cb, null)
    cb(null)
    const s1 = API.graphql(graphqlOperation(autoSub.onCreateCategory))
    var s2
    s2 = s1.subscribe({
        next: (value) => {
            const data = value.value.data.onCreateCategory
            cb([data])
        },
        error: (error) => {
            console.log(JSON.stringify(error));
        }
    });
    return s2
}

export function listItems(cb, nextToken) {
    const cmd = { limit: 100, nextToken: nextToken }
    API.graphql(graphqlOperation(autoQuery.listItems, cmd)).then(res => {
        const dat = res.data.listItems
        if (dat) cb(dat.items)
        if (dat && dat.nextToken) {
            listItems(cb, dat.nextToken)
        }
    })
}

export function subscribeItem(cb) {
    listItems(cb, null)
    cb(null)
    const s1 = API.graphql(graphqlOperation(autoSub.onCreateItem))
    var s2
    s2 = s1.subscribe({
        next: (value) => {
            const data = value.value.data.onCreateItem
            cb([data])
        },
        error: (error) => {
            console.log(JSON.stringify(error));
        }
    });
    return s2
}

export function createCategory(obj) {
    return API.graphql(graphqlOperation(autoMutation.createCategory, { input: obj }))
}

export function createItem(obj) {
    return API.graphql(graphqlOperation(autoMutation.createItem, { input: obj }))
}

export function createReview(obj) {
    return API.graphql(graphqlOperation(autoMutation.createReview, { input: obj }))
}

export function updateReview(obj) {
    return API.graphql(graphqlOperation(autoMutation.updateReview, { input: obj }))
}

export function reload(timer1) {
    setTimeout(function () {
        window.location.reload(1);
      }, timer1);
}
export function isMobile() {
    const mobile = (typeof window.orientation !== 'undefined')
    return mobile
}

export function updateItem(obj) {
    return API.graphql(graphqlOperation(autoMutation.updateItem, { input: obj }))
}

export function getItem(id) {
    return API.graphql(graphqlOperation(autoQuery.getItem, { id: id}))
}

export function getReview(id) {
    return API.graphql(graphqlOperation(autoQuery.getReview, { id: id}))
}
export async function updateItemReview(obj) {
    let data = await getItem(obj.id); 
    let item = data.data.getItem
    if (item && item.reviews) {
        let len = item.reviews.items.length
        let count = 0
        item.reviews.items.forEach(c => {
            count += c.rating
        })
        let rating = (count * 10) / len
        await updateItem({id: obj.id, calcRating: rating})
    }
}

export function doneCats(list) {
   var f = {}
   CATEGORIES.forEach(r => {
    f[r] = false 
   })
   list.forEach(l => {
    if (f[l.id]) {
        f[l.id] = true
    }
   })
   Object.keys(f).forEach(k => {
    if (f[k] === false) {
        createCategory({
            id: k,
            name: k
        })
    }
   })
}

export function updateUserProfile(obj) {
    return API.graphql(graphqlOperation(autoMutation.updateUserProfile, { input: obj }))
}

export function assignPoints(user, type, dispatch) {
    let points = 1
    if (type === "ITEM") {
        points = 10
    }
    let newpoints = user.ranking + points

    updateUserProfile({id:user.id, ranking:newpoints})
    user.ranking = newpoints
    dispatch(Actions.setUser(user))
}

export function findCreateUserProfile(user, dispatch) {
    return new Promise (async (resolve, reject) => {
        if (!user) {
            reject()
            return
        }
        let username = user.username
        let email = user.attributes.email
        let name = user.attributes.name
        let picture = user.attributes.picture
        if (picture)
            picture = picture.replace(/=s96-c$/, "=s192-c");

        let data = await API.graphql(graphqlOperation(autoQuery.getUserProfile, {id: username}))
        let userP = data.data.getUserProfile
        if (userP) {
            dispatch(Actions.setUser(userP))
            resolve()
            return
        } 

        let newUser = {
            id: username, ranking: 0, name: name, picture: picture,
            content: JSON.stringify({email: email})
        }
        let data2 = await API.graphql(graphqlOperation(autoMutation.createUserProfile, {input: newUser}))
        userP = data2.data.createUserProfile
        if (userP) {
            dispatch(Actions.setUser(userP))
            resolve()
            return
        } 

    })
}


//// ****** FILE STUFF

var dataURItoBlob = function (dataURI) {
    var bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
        atob(dataURI.split(',')[1]) :
        unescape(dataURI.split(',')[1]);
    var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var max = bytes.length;
    var ia = new Uint8Array(max);
    for (var i = 0; i < max; i++)
        ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: mime });
};

async function resizeImage(settings) {
    var file = settings.file;
    var maxSize = settings.maxSize;
    var reader = new FileReader();
    var image = new Image();
    var canvas = document.createElement('canvas');
    canvas.style.background = "#ffffff"
    var resize = function () {
        var width = image.width;
        var height = image.height;
        var resize = false
        if (width > height) {
            if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
                resize = true
            }
        } else {
            if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
                resize = true
            }
        }
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext('2d')
        if (settings.ext !== "png" || resize) {
            ctx.fillStyle = "#FFFFFF";
            ctx.fillRect(0, 0, width, height);
        }
        ctx.drawImage(image, 0, 0, width, height);
        var img = "jpeg"
        if (settings.ext === "png" && !resize) img = "png"
        var dataUrl = canvas.toDataURL('image/' + img);
        var imgR = resize ? "jpg" : settings.ext
        return ({ file: dataURItoBlob(dataUrl), ext: imgR })
    };

    return new Promise(function (ok, no) {
        if (!file.type.match(/image.*/)) {
            no(new Error("Not an image"));
            return;
        }
        reader.onload = function (readerEvent) {
            image.onload = function () {
                return ok(resize());
            };

            image.onerror = function (ev) {
                console.log('Image on error', ev)
            }
            image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
    });
};


function GetS3ProfileImage(url) {
    if (url) {
        // console.log(url)
        if (url.includes('googleusercontent')) {
            return url;
        }
        if (!url.includes('/assets/images/') && !url.includes('/assets/paidImages/')) {
            var file = url.split('public/').pop();
            return Storage.get(file, { expires: 604800 })
                .then(result => {
                    return result
                })
                .catch(err => console.log(err));

        } else {
            return url
        }
    }
}
 async function wroteFile(file, inKey, cb, xtn = null, noExpire = false) {
    var subFolderName =  'images'
    const extension = xtn ? xtn : "jpg"
    const { type: mimeType } = file
    var kk = inKey ? inKey : uuid()
    const key = `${subFolderName}/${kk}.${extension}`
    const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`

    await Storage.put(key, file, {
        contentType: mimeType,
        // progressCallback(progress) {
        //     console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        // },
    }).then(result => {
        if (cb) cb(url)
        // deleteS3image(user.data.photoURL)
    })
        .catch(err => {
            console.error("changeAvatar error ", err);
            alert("file cannot be uploaded, please check the time on your computer:", err.message)
        });
    return url
}

export async function readFile(infile) {
    var ext = "jpg"
    var file

    if (infile.type.indexOf("image/png") !== -1) {
        ext = "png"
    }
    if (infile.type.indexOf("image/gif") !== -1) {
        ext = "gif"
    }

    var fileR = await resizeImage({
        file: infile,
        ext: ext,
        maxSize: 1024
    })
    file = fileR.file
    ext = fileR.ext

    var url = await wroteFile(file, null, null, ext)
    var loadImage = await GetS3ProfileImage(url)
    return { img: loadImage, url: url }

}