import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';

function App({ signOut, user }) {
  const userProfile = useSelector((state) => state.userProfile)

  const open = userProfile ? false : true
  return (
    <Dialog open={Boolean(open)} >

      <Authenticator socialProviders={['google']}>
        {({ signOut, user }) => {
          // ib.reload(2000);
          // <main>
          //   <h1>Hello {user.username}</h1>
          //   <button onClick={signOut}>Sign out</button>
          // </main>
        }}
      </Authenticator>
    </Dialog>

  );
}

export default App;