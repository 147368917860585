/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      content
      createdAt
      updatedAt
      items {
        items {
          id
          name
          categoryString
          description
          content
          calcRating
          location
          latLong
          airportCode
          picture
          createdAt
          updatedAt
          lat
          lng
          gas
          anon
          aiInfo
          categoryItemsId
          userProfileItemsId
        }
        nextToken
      }
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        content
        createdAt
        updatedAt
        items {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      name
      categoryString
      category {
        id
        name
        content
        createdAt
        updatedAt
        items {
          nextToken
        }
      }
      description
      content
      calcRating
      location
      latLong
      airportCode
      picture
      createdAt
      updatedAt
      reviews {
        items {
          id
          rating
          createdAt
          updatedAt
          content
          vote
          anon
          itemReviewsId
          userProfileReviewsId
        }
        nextToken
      }
      user {
        id
        ranking
        name
        picture
        createdAt
        updatedAt
        content
        reviews {
          nextToken
        }
        items {
          nextToken
        }
      }
      lat
      lng
      gas
      anon
      aiInfo
      categoryItemsId
      userProfileItemsId
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        categoryString
        category {
          id
          name
          content
          createdAt
          updatedAt
        }
        description
        content
        calcRating
        location
        latLong
        airportCode
        picture
        createdAt
        updatedAt
        reviews {
          nextToken
        }
        user {
          id
          ranking
          name
          picture
          createdAt
          updatedAt
          content
        }
        lat
        lng
        gas
        anon
        aiInfo
        categoryItemsId
        userProfileItemsId
      }
      nextToken
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      item {
        id
        name
        categoryString
        category {
          id
          name
          content
          createdAt
          updatedAt
        }
        description
        content
        calcRating
        location
        latLong
        airportCode
        picture
        createdAt
        updatedAt
        reviews {
          nextToken
        }
        user {
          id
          ranking
          name
          picture
          createdAt
          updatedAt
          content
        }
        lat
        lng
        gas
        anon
        aiInfo
        categoryItemsId
        userProfileItemsId
      }
      rating
      createdAt
      updatedAt
      content
      user {
        id
        ranking
        name
        picture
        createdAt
        updatedAt
        content
        reviews {
          nextToken
        }
        items {
          nextToken
        }
      }
      vote
      anon
      itemReviewsId
      userProfileReviewsId
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        item {
          id
          name
          categoryString
          description
          content
          calcRating
          location
          latLong
          airportCode
          picture
          createdAt
          updatedAt
          lat
          lng
          gas
          anon
          aiInfo
          categoryItemsId
          userProfileItemsId
        }
        rating
        createdAt
        updatedAt
        content
        user {
          id
          ranking
          name
          picture
          createdAt
          updatedAt
          content
        }
        vote
        anon
        itemReviewsId
        userProfileReviewsId
      }
      nextToken
    }
  }
`;
export const getUserProfile = /* GraphQL */ `
  query GetUserProfile($id: ID!) {
    getUserProfile(id: $id) {
      id
      ranking
      name
      picture
      createdAt
      updatedAt
      content
      reviews {
        items {
          id
          rating
          createdAt
          updatedAt
          content
          vote
          anon
          itemReviewsId
          userProfileReviewsId
        }
        nextToken
      }
      items {
        items {
          id
          name
          categoryString
          description
          content
          calcRating
          location
          latLong
          airportCode
          picture
          createdAt
          updatedAt
          lat
          lng
          gas
          anon
          aiInfo
          categoryItemsId
          userProfileItemsId
        }
        nextToken
      }
    }
  }
`;
export const listUserProfiles = /* GraphQL */ `
  query ListUserProfiles(
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ranking
        name
        picture
        createdAt
        updatedAt
        content
        reviews {
          nextToken
        }
        items {
          nextToken
        }
      }
      nextToken
    }
  }
`;
