import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from "./store/actions"
import * as ib from './ibdata';
import Rating from '@mui/material/Rating';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import AuthDialog from "./AuthDialog";

export default function AddReview(pp) {
    const dispatch = useDispatch();
    const open = useSelector((state) => state.reviewDialog.open)
    const props = useSelector((state) => state.reviewDialog.props)

    const user = useSelector((state) => state.userProfile)

    const [form, setForm] = React.useState({
        rating: props.value,
        name: props.name,
        content: '',
        anon: true,
    })

    async function createItem() {
        let rt = parseInt(form.rating, 10)
        let item = {
            itemReviewsId: props.id,
            userProfileReviewsId: user.id,
            rating: rt,
            vote: 0,
            anon: form.anon,
            content: form.content,
        }
        if (form.anon === true) {
            item.userProfileReviewsId = undefined
        }
        ib.assignPoints(user, "RATING", dispatch)
        await ib.createReview(item)
        await ib.updateItemReview(props)
        handleClose()
    }

    const handleClose = () => {
        dispatch(Actions.setReviewDialog(null))
    };

    function handleChange(e, d) {
        let what = e.target.name
        let value = e.target.value
        var newval = {}
        if (what === "anon") value = e.target.checked
        setForm(p => {
            newval = {
                ...p,
                [what]: value
            }
            return newval
        })
    }

    function ratingPress(c, d) {
        setForm(p => ({ ...p, rating: d }))
    }
    if (!user) {
        return (
            <AuthDialog />
        )
    }
    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Review {props.name}
                </DialogTitle>
                <DialogContent>
                    <Rating name="rating" value={form.rating} onChange={ratingPress} />

                    <TextField
                        name="content"
                        label="Review Comments"
                        fullWidth
                        multiline
                        rows={2}
                        variant="standard"
                        value={form.content}
                        onChange={handleChange}
                    />
                    <FormGroup>
                        <FormControlLabel control={<Switch size="small" checked={form.anon} onChange={handleChange} name="anon" />} label="Post anonymously" />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={createItem} variant="contained" >Add</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}