import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from "./store/actions"
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as ib from './ibdata';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
// import  from 'react-google-places-autocomplete';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Grid from "@mui/material/Grid";
import AuthDialog from "./AuthDialog";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const API = "AIzaSyClc8o6lO0O4FbgAmqTodCNkiUaY8ksV6k"

const RESETVALUES = {
    category: 'Airport',
    name: '',
    description: '',
    location: '',
    latLng: '{}',
    picture: '',
    airportCode: '',
    link: '',
    anon: false,
}
export default function AddItem(props) {
    const dispatch = useDispatch();
    const open = useSelector((state) => state.addItemDialog.open)
    const values = useSelector((state) => state.addItemDialog.props)

    const user = useSelector((state) => state.userProfile)
    const [value, setVValue] = React.useState(null);
    const [form, setForm] = React.useState({
        ...RESETVALUES
    })

    React.useEffect(() => {
        if (!values) {
            setForm({ ...RESETVALUES })
            return
        }
        var newval = {

            category: values.category.id,
            name: values.name,
            description: values.description,
            location: values.location,
            latLng: values.latLong,
            picture: values.picture,
            airportCode: values.airportCode,
            link: values.content,
            anon: values.anon,
        }
        setVValue({ label: values.location })
        setForm(newval)
    }, [values])
    function GetLatlong(place) {
        return new Promise((resolve, reject) => {
            var geocoder = new window.google.maps.Geocoder();
            //var address = document.getElementById('textboxid').value;

            geocoder.geocode({
                'address': place
            }, function (results, status) {
                if (status === window.google.maps.GeocoderStatus.OK) {
                    var latitude = results[0].geometry.location.lat();
                    var longitude = results[0].geometry.location.lng();
                    resolve({ lat: latitude, lng: longitude })
                }
            });
        })
    }

    async function setValue(val) {
        if (!val) {
            setVValue(val)
            return
        }
        let ll = await GetLatlong(val.label)
        //        const geocoder = new window.google.maps.Geocoder();
        setVValue(val)
        var newval = {}
        setForm(p => {
            newval = {
                ...p,
                'latLng': JSON.stringify(ll),
                'location': val.label,
            }
            return newval
        })
    }

    async function updateItem() {
        let ll = null
        if (form.latLng.length > 3) {
            ll = JSON.parse(form.latLng)
        }
        let item = {
            id: values.id,
            name: form.name,
            categoryString: form.category,
            categoryItemsId: form.category,
            location: form.location,
            latLong: form.latLng,
            picture: form.picture,
            content: form.link,
            airportCode: form.airportCode,
            description: form.description,
            lat: ll ? ll.lat : undefined,
            lng: ll ? ll.lng : undefined,
        }

        ib.assignPoints(user, "ITEM", dispatch)
        await ib.updateItem(item)
        handleClose()
        setTimeout(function () {
            window.location.reload(1);
        }, 500);
    }

    async function createItem() {
        let ll = null
        if (form.latLng.length > 3) {
            ll = JSON.parse(form.latLng)
        }
        let item = {
            name: form.name,
            categoryString: form.category,
            categoryItemsId: form.category,
            location: form.location,
            latLong: form.latLng,
            picture: form.picture,
            content: form.link,
            airportCode: form.airportCode,
            description: form.description,
            userProfileItemsId: form.anon === false ? user.id : undefined,
            calcRating: 0,
            anon: form.anon,
            lat: ll ? ll.lat : undefined,
            lng: ll ? ll.lng : undefined,
        }

        ib.assignPoints(user, "ITEM", dispatch)
        await ib.createItem(item)
        handleClose()
    }

    const handleClose = () => {
        dispatch(Actions.setAddItem(null))
    };

    function handleChange(e, d) {
        let what = e.target.name
        let value = e.target.value
        var newval = {}
        if (what === "anon") value = e.target.checked
        setForm(p => {
            newval = {
                ...p,
                [what]: value
            }
            return newval
        })
    }

    async function handleCapture(target) {
        let val = await ib.readFile(target.target.files[0])

        let what = "picture"
        let value = val.url
        let newval = {}
        setForm(p => {
            newval = {
                ...p,
                [what]: value
            }
            return newval
        })
    }

    if (!user) {
        return (
            <AuthDialog />
        )
    }
    return (
        <div>
            <Dialog open={open} maxWidth='sm' fullWidth='true' onClose={handleClose}>
                <DialogTitle>Add New Item</DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>
                        To subscribe to this website, please enter your email address here. We
                        will send updates occasionally.
                    </DialogContentText> */}
                    <FormControl fullWidth style={{ marginTop: "5px" }}>
                        <InputLabel id="demo-simple-select-helper-label">Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={form.category}
                            label="Category"
                            name="category"
                            onChange={handleChange}
                        >
                            {ib.CATEGORIES.map(element => (
                                <MenuItem value={element} key={element}>
                                    {element}
                                </MenuItem>

                            ))}
                        </Select>
                    </FormControl>
                    <Grid container direction={"column"} spacing={2}>
                        <Grid item>
                            <TextField
                                name="name"
                                style={{ marginRight: "0.3em" }}
                                label="Name"
                                variant="standard"
                                required
                                fullWidth
                                value={form.name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item>

                            <TextField
                                name="airportCode"
                                style={{ marginRight: "0.3em" }}
                                label="Airport Code (optional)"
                                variant="filled"
                                value={form.airportCode}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                name="link"
                                label="Website Link"
                                variant="standard"
                                value={form.link}
                                fullWidth
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item>

                            <TextField
                                name="description"
                                label="Description"
                                fullWidth
                                multiline
                                rows={2}
                                variant="standard"
                                value={form.description}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item>
                            <GooglePlacesAutocomplete
                                autocompletionRequest={{ fields: "geometry" }}
                                fetchDetails={true}
                                selectProps={{
                                    value,
                                    isClearable: true,
                                    onChange: setValue,
                                    placeholder: 'Address...',
                                }}
                                apiKey={API}
                            />
                        </Grid>
                    </Grid>


                    <div className="formImage">
                        {(form.picture.length > 0) && (
                            <>
                                <img src={form.picture} alt="item " width="300px" style={{ margin: "1em" }} />
                            </>
                        )}
                        {(form.picture.length <= 0 || values) && (
                            <>
                                <input
                                    accept="image/*"
                                    className="displayNone"
                                    id="icon-button-photo"
                                    onChange={handleCapture}
                                    type="file"
                                />
                                <label htmlFor="icon-button-photo">
                                    <IconButton color="primary" component="span" >
                                        <AddAPhotoIcon style={{ fontSize: "2em" }} />
                                    </IconButton>
                                </label>
                            </>
                        )}
                    </div>
                    <FormGroup>
                        <FormControlLabel control={<Switch size="small" checked={form.anon} onChange={handleChange} name="anon" />} label="Post anonymously" />
                    </FormGroup>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    {values ? (
                        <Button onClick={updateItem} variant="contained" >Update</Button>
                    ) : (
                        <Button onClick={createItem} variant="contained" >Add</Button>
                    )}
                </DialogActions>
            </Dialog>
        </div >
    );
}