import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import * as Actions from "./store/actions"
import Rating from '@mui/material/Rating';
import Button from '@mui/material/Button';

export default function DataTable(props) {
    const [rows, setRows] = React.useState([])
    const dispatch = useDispatch();

    const [pageSize, setPageSize] = React.useState(10);
    function RenderImage(props) {
        return (
            <img alt="item name" height="30px" src={props.value} />
        )
    }

    function RenderDetails(props) {
        function showDetail() {
            window.open("/item/" + props.value)
        }
        return (
            <Button size="small" onClick={showDetail}>Details</Button>

        )
    }
    function RatingImage(props) {
        const rating = props.value / 10

        function ratingPress(c, d) {
            dispatch(Actions.setReviewDialog({
                open: true,
                props: { ...props, value: d }
            }))
        }
        return (
            <Rating name="size-small" value={rating} precision={0.5} onChange={ratingPress} size="small" />

        )
    }
    const columns = [
        {
            field: 'categoryString',
            headerName: 'Catergory',
            width: 100,
            editable: false,
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 180,
            editable: false,
        },
        {
            field: 'gas',
            headerName: 'Gas',
            width: 120,
            editable: false,
        },
        {
            field: 'calcRating',
            headerName: 'Rating',
            width: 120,
            editable: false,
            renderCell: (params) => <RatingImage {...params} />, // renderCell will render the component
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 250,
            editable: false,
        },
        {
            field: 'picture',
            headerName: 'picture',
            width: 100,
            editable: false,
            renderCell: (params) => <RenderImage {...params} />, // renderCell will render the component
        },
        {
            field: 'id',
            headerName: 'Details',
            width: 100,
            editable: false,
            renderCell: (params) => <RenderDetails {...params} />, // renderCell will render the component
        },
    ];

    React.useEffect(() => {
        let rows1 = []
        if (props.items) {
            Object.keys(props.items).forEach(m => {
                Object.keys(props.items[m]).forEach(k => {
                    let item = props.items[m][k]
                    rows1.push(item)
                })
            })
            setRows(rows1)
        }

    }, [props.items])
    return (
        <Box sx={{ height: "80vh", width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                // pageSize={15}
                autoPageSize
                // initialState={{
                //     pagination: {
                //       pageSize: 15,
                //     },
                //   }}
                pageSize={pageSize}
                onPageSizeChange={(newPage) => setPageSize(newPage)}
                disableSelectionOnClick
                // rowsPerPageOptions={[10, 20, 50]}
            />
        </Box>
    );
}