import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Rating from '@mui/material/Rating';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import IconButton from '@mui/material/IconButton';

import * as ib from "./ibdata";

export default function ReviewCard(pp) {
    const [props, setProps] = React.useState(null);
    React.useEffect(() => {
        ib.getReview(pp.id).then(c => {
            if (c && c.data && c.data.getReview) {
                setProps(c.data.getReview)
            }
        })
    }, [pp.id])

    function countVote(val) {
        let p = { ...props }
        p.vote += val
        ib.updateReview({ id: p.id, vote: p.vote })
        setProps(p)
    }

    if (!props)
        return (
            <>
            </>
        )
    const rating = props.rating;
    return (
        <Card sx={{ maxWidth: 345, backgroundColor: "aliceblue" }}>

            <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                    <div className='Rating'>
                        <Rating name="size-small" value={rating} precision={0.5} readOnly={true} size="small" />
                        <div className='RatingVote'>
                            <IconButton size="small" onClick={() => countVote(1)}>
                                <ThumbUpIcon fontSize='8px' />
                            </IconButton>
                            <IconButton size="small" onClick={() => countVote(-1)} >
                                <ThumbDownIcon fontSize='8px' />
                            </IconButton>
                            <span>

                                {props.vote ? props.vote : ""}
                            </span>

                        </div>
                    </div>

                </Typography>

                <Typography variant="body2" color="text.secondary">
                    {props.content}
                </Typography>


            </CardContent>
            <CardActions className='cardActions'>

                {!props.anon && (
                    <>
                        <Chip
                            avatar={<Avatar alt={props.user.name} src={props.user.picture} />}
                            label={props.user.name}
                            size="small"
                        />
                        <span className='goldContent'>
                            {props.user.ranking}
                            <AirplaneTicketIcon />
                        </span>
                    </>
                )}
            </CardActions>

        </Card>
    );
}