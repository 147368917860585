import * as Actions from "./actions"

const initialState = {
    userProfile: undefined,
    addItemDialog: { open: false, props: null },
    reviewDialog: { open: false, props: null },
    filterCat: [],
    filterLoc: { location: null, distance: null },
    storedItems: {},
    filteredItems: {},
    mapCoords: null,
    searchString: "",
    tableView: false
}

function setFilterCat(state, dat, mc, ss) {
    let copy = JSON.parse(JSON.stringify(state.storedItems));
    let k = Object.keys(copy);

    k.forEach(t => {
        if (dat.length > 0) {
            if (!dat.includes(t)) {
                delete copy[t]
            }
        }
    })
    if (mc || ss.length > 0) {
        k = Object.keys(copy);
        k.forEach(t => {
            Object.keys(copy[t]).forEach(r => {
                let item = copy[t][r]
                // console.log("CHECK",item, t, r)
                if (mc && item && item.lat && item.lng) {
                    let keep = false

                    if (item.lat >= mc.bottomRight.lat &&
                        item.lat <= mc.topLeft.lat &&
                        item.lng <= mc.topLeft.lng &&
                        item.lng >= mc.bottomRight.lng)
                        keep = true
                    if (!keep) {
                        delete copy[t][r]
                    }
                }
                if (item && ss.length > 0) {
                    let keep = false
                    let itemLo = item.name.toLowerCase()
                    let ssLo = ss.toLowerCase()
                    if (itemLo.includes(ssLo)) {
                        keep = true
                    }
                    if (!keep) {
                        delete copy[t][r]
                    }
                }
            })
        })
    }

    return {
        ...state,
        filterCat: dat,
        mapCoords: mc,
        searchString: ss, 
        filteredItems: copy,
    }
}
const mainReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.FILTER_CAT: {

            let dat = action.data ? action.data : []
            return setFilterCat(state, dat, state.mapCoords, state.searchString)
        }
        case Actions.SET_SEARCH_STRING: {
            return setFilterCat(state, state.filterCat, state.mapCoords, action.data)
        }
        case Actions.SET_MAP_COORDS: {
            return setFilterCat(state, state.filterCat, action.data, state.searchString)
        }
        case Actions.SET_TABLE_VIEW: {
            return {
                ...state,
                tableView: action.data
            }
        }
        case Actions.SET_USER: {
            return {
                ...state,
                userProfile: action.data
            }
        }
        case Actions.SET_ITEMS: {
            let items = {
                ...state.storedItems,
                ...action.data
            }
            return {
                ...state,
                storedItems: items,
                filteredItems: items,
            }
        }
        case Actions.ADD_ITEM_DIALOG: {
            return {
                ...state,
                addItemDialog: action.data ? action.data : { open: false, props: null }
            }
        }
        case Actions.REVIEW_DIALOG: {
            return {
                ...state,
                reviewDialog: action.data ? action.data : { open: false, props: null }
            }
        }
        default:
            return state;
    }
}

export default mainReducer

