import React from 'react'
import { Loader } from "@googlemaps/js-api-loader";
import { useSelector, useDispatch } from 'react-redux';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import * as Actions from "./store/actions"

const California = { lat: 37.0902, lng: -95.7129 }
var map
// var mapupdater;
// let firstLoad = true
var markerCluster;

export default function Map() {
    const filteredItems = useSelector((state) => state.filteredItems)
    const [markers, setMarkers] = React.useState([])
    const dispatch = useDispatch();

    React.useEffect(() => {
        let mm = []
        Object.keys(filteredItems).forEach(k => {
            Object.keys(filteredItems[k]).forEach(r => {
                let item = filteredItems[k][r]
                if (item.latLong) {
                    let ll = JSON.parse(item.latLong)
                    mm.push({ position: { ...ll }, item: item })
                }
            })
        })
        setMarkers(mm)
    }, [filteredItems])

    React.useEffect(() => {
        if (map) return
        let loader = new Loader({
            apiKey: "AIzaSyClc8o6lO0O4FbgAmqTodCNkiUaY8ksV6k",
            version: "weekly",
            libraries: ["places"]
        })
        loader.load().then(() => {
            loadMap(California)

        }).catch((e) => {
            console.error(e)
        })
        // eslint-disable-next-line
    }, [])

    function markerClick(m) {
        window.open("/item/" + m.item.id)
    }
    const MARKERICONS = {
        'Airport': "\ue904",
        'FBO': "\ue546",
        'Restaurant': "\ue57a",
        'Gear': "\uf10b",
        "Flight School": "\ue80c",
        'DPE': "\ue7fd",
        'CFI': "\ue7fd",
        'Sight Seeing': "\ue2db"
    }
    React.useEffect(() => {
        if (map && markers.length > 0) {
            let bounds = new window.google.maps.LatLngBounds();
            let gmarkers = []
            if (markerCluster) {
                markerCluster.clearMarkers()
            }
            markers.forEach((mark) => {
                let loc = new window.google.maps.LatLng(mark.position.lat, mark.position.lng);
                bounds.extend(loc);

                let marker = new window.google.maps.Marker({
                    position: loc,
                    label: {
                        text: MARKERICONS[mark.item.category.id], // codepoint from https://fonts.google.com/icons
                        fontFamily: "Material Icons",
                        color: "#ffffff",
                        fontSize: "18px",
                    },
                    // label: mark.item.name,
                });
                gmarkers.push(marker)
                let gas = mark.item.gas && (mark.item.category.id === "Airport" || mark.item.category.id ===  "FBO")? mark.item.gas : ""
                var contentString = '<div id="content">' +
                    '<div class="smallGas">' + gas +
                    '</div>' +
                    '<h4 id="firstHeading" class="firstHeading">' + mark.item.name + '</h4>' +
                    '<div class="bodyContent">' + mark.item.description +
                    '<p>' +
                    '<img height=100px src="' + mark.item.picture + '" />' +
                    '</div>' +
                    '</div>';
                var infoWindow = new window.google.maps.InfoWindow({
                    content: contentString
                });
                marker.addListener('mouseover', () => infoWindow.open(map, marker))
                marker.addListener('mouseout', () => infoWindow.close())
                marker.addListener("click", () => {
                    markerClick(mark)
                });
            })
            markerCluster = new MarkerClusterer({ markers: gmarkers, map });
            map.fitBounds(bounds);
            map.panToBounds(bounds);
        }
        // eslint-disable-next-line
    }, [markers])

    function getMapBounds() {
        let map = window.googlemap
        let bounds = map.getBounds();
        let ne = bounds.getNorthEast(); // Coords of the northeast corner
        let sw = bounds.getSouthWest(); // Coords of the southwest corner
        dispatch(Actions.setMapCoords({
            topLeft: { lat: ne.lat(), lng: ne.lng() },
            bottomRight: { lat: sw.lat(), lng: sw.lng() },
        }))
        // let nw = new google.maps.LatLng(ne.lat(), sw.lng()); // Coords of the NW corner
        // let se = new google.maps.LatLng(sw.lat(), ne.lng()); // Coords of the SE corner
        // console.log(ne.toString(), sw.toString(), nw.toString(), se.toString());
    }

    function loadMap(cent) {
        if (map) {
            map.panTo(cent)

            if (markers.length > 0) {
                let bounds = new window.google.maps.LatLngBounds();
                markers.forEach((mark) => {
                    let loc = new window.google.maps.LatLng(mark.position.lat, mark.position.lng);
                    bounds.extend(loc);
                })
                map.fitBounds(bounds);
                map.panToBounds(bounds);
            }
            return
        }
        if (!ref || !ref.current || map) {
            return
        }
        let google = window.google

        map = new window.google.maps.Map(ref.current, {
            center: { lat: 37.09, lng: -95.712 },
            zoom: 4,
            fullscreenControl: false,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                position: google.maps.ControlPosition.TOP_RIGHT,
            }
        });
        window.googlemap = map

        // window.google.maps.event.addListener(map, "dragend", mapSettleTime);
        // window.google.maps.event.addListener(map, "zoom_changed", mapSettleTime);


        // function mapSettleTime() {
        //     clearTimeout(mapupdater);
        //     mapupdater = setTimeout(getMapBounds, 1000);
        // }
        const card = document.getElementById("pac-card");
        const input = document.getElementById("pac-input");
        const options = {
            // fields: ["formatted_address", "geometry", "name"],
            // strictBounds: false,
            // types: ["establishment"],
        };
        map.controls[google.maps.ControlPosition.TOP_LEFT].push(card);

        const autocomplete = new google.maps.places.Autocomplete(input, options);
        autocomplete.addListener("place_changed", placeChanged);
        function placeChanged() {
            console.log("FOUND PLACE")

            const place = autocomplete.getPlace();
            if (!place) {
                return;
            }
            // For each place, get the icon, name and location.
            const bounds = new google.maps.LatLngBounds();
            if (!place.geometry || !place.geometry.location) {
                console.log("Returned place contains no geometry");
                return;
            }

            const icon = {
                url: place.icon,
                size: new google.maps.Size(71, 71),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(17, 34),
                scaledSize: new google.maps.Size(25, 25),
            };

            // Create a marker for each place.
            markers.push(
                new google.maps.Marker({
                    map,
                    icon,
                    title: place.name,
                    position: place.geometry.location,
                })
            );

            if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }

            map.fitBounds(bounds);
        }

        // window.google.maps.event.addListener(map, 'idle', function () {
        //     mapSettleTime()
        // });
        // Add radius overlay to map
        // new google.maps.Circle({
        //     strokeColor: "#f38038",
        //     strokeOpacity: 0.4,
        //     strokeWeight: 2,
        //     fillColor: "#f38038",
        //     fillOpacity: 0.25,
        //     map: map,
        //     center: new google.maps.LatLng(California.lat, California.lng),
        //     radius: 200 * 1000, // 20km
        // });
    }

    const ref = React.useRef(null);
    return (
        <>
            <div className="pac-card" id="pac-card">
                <div>
                    <div id="title">Seach Map</div>
                    <div id="type-selector" className="pac-controls">

                        <div id="pac-container">
                            <input id="pac-input" type="text" placeholder="Enter Location.." />

                        </div>
                        <button
                            name="resetSearch"
                            id="resetSearch"
                            onClick={getMapBounds}
                        >
                            Filter by map
                        </button>
                    </div>
                </div>
            </div>
            <div className="mapClass" ref={ref} id="map" />
        </>
    )
}